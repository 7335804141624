import { zodResolver } from "@hookform/resolvers/zod";
import { Head, Link, router } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { LoadingSpinner } from "@/components/LoadingSpinner";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@/components/ui";
import { usePageData } from "@/hooks/usePageData";

const DevelopmentLogins = () => {
  const { environment } = usePageData();

  //  Don't render if not in a local environment. Route is also only registered when in a local environment.
  if (environment !== "local") {
    return;
  }

  const profiles = [
    {
      email: "system.admin@octfolio.com",
      label: "SUPER USER PERMISSIONS",
      role: "super_admin",
    },
    {
      email: "system.admin@octfolio.com",
      label: "OWNER PERMISSIONS",
      role: "owner",
    },
    {
      email: "donny@octfolio.com",
      label: "ADMIN PERMISSIONS",
      role: "admin",
    },
    {
      email: "seb.staff@octfolio.com",
      label: "STAFF PERMISSIONS",
      role: "staff",
    },
    {
      email: "jordan.havard.contractor@octfolio.com",
      label: "CONTRACTOR PERMISSIONS",
      role: "contractor",
    },
  ];

  return (
    <div className="flex flex-col">
      {profiles.map((profile) => (
        <div key={profile.role + profile.email}>
          <Link
            className="text-body-text-default"
            href={route("loginLinkLogin", {
              email: profile.email,
              role: profile.role,
            })}
            as="button"
            method="post"
          >
            {profile.label} ({profile.email})
          </Link>
        </div>
      ))}
    </div>
  );
};

export default function Login() {
  return (
    <>
      <Head title={"Login"} />
      <div className="absolute inset-0 mx-auto flex h-screen w-full flex-col bg-gray-100">
        <div className="flex max-w-full flex-auto flex-col bg-red-500 md:w-5/12">
          <div className="absolute z-20 flex items-center p-8 text-lg font-medium">
            <img
              className="text-white"
              src="/media/octfolio-als-white.svg"
              width="200em"
              alt="Octfolio logo"
            />
          </div>
          <div className="absolute inset-0 hidden bg-[url(/media/auth-bg.webp)] bg-cover md:flex md:w-5/12" />
          <div className="absolute inset-0 hidden flex-col bg-black opacity-60 md:flex md:w-5/12" />
        </div>
        <div className="ml-auto flex h-full w-full grow bg-body-surface-default shadow-xl md:w-7/12">
          <div className="absolute">
            <DevelopmentLogins />
          </div>
          <div className="flex w-full flex-col p-8">
            <div className="flex justify-end space-x-2"></div>
            <div className="flex grow items-center">
              <div className="mx-auto w-full max-w-md">
                <LoginForm />
              </div>
            </div>
            <div className="bottom-0 flex h-8 items-center text-xs text-body-text-x-subtle">
              <span>
                Photo by{" "}
                <a
                  className="underline"
                  href="https://unsplash.com/@timothy_swope?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
                  target="_blank"
                >
                  Timothy Swope
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const loginFormSchema = z.object({
  email: z
    .string({ required_error: "Please enter your email" })
    .email({ message: "Please enter a valid email" }),
  password: z
    .string({ required_error: "Please enter your password" })
    .min(1, "Please enter your password"),
});

type LoginFormData = z.infer<typeof loginFormSchema>;

const LoginForm = () => {
  const form = useForm<LoginFormData>({
    resolver: zodResolver(loginFormSchema),
  });

  const login = async (values: LoginFormData) => {
    try {
      await new Promise((resolve, reject) => {
        router.post(route("login"), values, {
          onStart: () => {
            form.clearErrors();
          },
          onSuccess: () => {
            resolve(() => {
              router.get(route("dashboard"), {}, { replace: true });
            });
          },
          onError: (errors) => {
            reject(errors);
          },
        });
      });
    } catch (errors) {
      const errorMap = Object.entries(errors as Record<string, string>);
      const errorMessage = errorMap[0][1];

      form.setError("root", {
        type: "manual",
        message: errorMessage,
      });
      form.setValue("password", "");
    }
  };

  const { environment } = usePageData();

  const callbackUrl = "/forgot-password?uri=" + btoa(window.location.href);

  const resetLink =
    environment === "production"
      ? "https://auth.octfolio.com" + callbackUrl
      : environment === "staging"
        ? "https://auth.staging.octfolio.com" + callbackUrl
        : "https://local-auth.octfol.io" + callbackUrl;

  if (form.formState.isSubmitting) {
    return <LoadingSpinner text="Signing you in securely..." />;
  }

  return (
    <div>
      <h1 className="inline-flex items-center pb-8 text-4xl font-normal text-body-text-default">
        <span>Sign in</span>
      </h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(login)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            defaultValue=""
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-body-text-default">
                    Email
                  </FormLabel>
                  <FormControl>
                    <Input type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="password"
            defaultValue=""
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-body-text-default">
                    Password
                  </FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormMessage>{form.formState.errors.root?.message}</FormMessage>
          {!form.formState.isSubmitting ? (
            <Button className="w-full" type="submit">
              Login
            </Button>
          ) : (
            <Button className="w-full" type="button" disabled>
              Please wait...
            </Button>
          )}
          <div className="flex justify-end">
            <Button variant="tertiary" className="p-0" asChild>
              <a href={resetLink} target="_blank">
                Forgot Password?
              </a>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
